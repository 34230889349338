import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import store from '../store';
import AnalyticsProvider from '../components/analytics/AnalyticsProvider';

import { updateHome } from '../actions/homes';

import Storage from '../utils/Storage';

import useAnalytics from '../components/analytics/useAnalytics';
import ExchangesReviewsInfo from '../components/home/exchanges-reviews-info';
import HomeLoader from '../components/home/HomeLoader';
import HomeDetail from '../components/home/HomeDetail';
import HomeSectionCollection from '../components/home/HomeSectionCollection';
import HomeHeader from '../components/home/HomeHeader';
import ContactButton from '../components/home/ContactButton';
import HomeDescription from '../components/home/HomeDescription';
import HomeViewCalendar from '../components/home/HomeViewCalendar';
import CalendarCaptionUpdate from '../components/calendar/CalendarCaptionUpdate';
import HomeViewCarousel from '../components/home/HomeViewCarousel';
import PreferredDestinations from '../components/home/PreferredDestinations';
import TravelingGroupInfo from '../components/home/TravelingGroupInfo';
import HomeRating from '../components/rating/HomeRating';
import HomeAggregateRating from '../components/rating/HomeAggregateRating';
import VerificationIndicators from '../components/user/verification/VerificationIndicators';
import HomeMap from '../components/home/HomeMap';
import Api from '../api/Api';
import HomepageNewest from '../components/homepage/HomepageNewest';
import UserSponsor from '../components/home/UserSponsor';
import UserAmbassador from '../components/common/ambassador/UserAmbassador';
import UserSponsorBadge from '../components/home/UserSponsorBadge';
import ResponseTime from '../components/user/view/ResponseTime';
import NewUserLabel from '../components/user/view/NewUserLabel';
import i18n from '../i18n';
import UserBadge from '../components/home/user-badge';
import HomeFeatures from '../components/home/HomeFeatures';
import useBadge from '../hooks/useBadge';
import Loading from '../components/common/Loading';
import initSentry from '../sentry';

initSentry();

const isLogged = document.getElementsByClassName('layout-login').length > 0;
if (!isLogged) {
    const bodyElement = document.querySelector('body');
    bodyElement.classList.add('home-view-clean-pad');
}
const homeView = document.getElementById('home-view');
const homeId = homeView ? parseInt(homeView.getAttribute('data-home'), 10) : null;
const userId = homeView ? parseInt(homeView.getAttribute('data-user'), 10) : null;

// Fetch data
const root = createRoot(document.createElement('div'));
root.render(
    <Provider store={store}>
        <HomeLoader userId={userId} homeId={homeId} isLogged={isLogged} />
    </Provider>
);

// Track page viewed
function HomeDetailViewed() {
    const { trackSpinalCord } = useAnalytics();
    React.useEffect(() => {
        try {
            trackSpinalCord('home-detail-viewed', { homeId });
        } catch (err) {
            console.error(err);
            // Sentry.captureException(err);
        }
    }, []);
    return false;
}

const homeDetail = document.getElementById('home-detail');
if (homeDetail) {
    createRoot(homeDetail).render(
        <Provider store={store}>
            <AnalyticsProvider eventsApiOptions={{ timeout: 1000 }}>
                {/* send spinal cord home-detail-viewed event */}
                <HomeDetailViewed />
            </AnalyticsProvider>
            <HomeDetail />
        </Provider>
    );
}

const homeFeatures = document.getElementById('home-features');
if (homeFeatures) {
    createRoot(homeFeatures).render(
        <Provider store={store}>
            <HomeFeatures homeId={homeId} />
        </Provider>
    );
}

const homeSectionCollection = document.getElementById('section-home-collection');
if (homeSectionCollection && isLogged) {
    createRoot(homeSectionCollection).render(
        <Provider store={store}>
            <HomeSectionCollection homeId={homeId} />
        </Provider>
    );
}

const homeHeader = document.getElementById('home-header-react');
if (homeHeader) {
    createRoot(homeHeader).render(
        <Provider store={store}>
            <HomeHeader homeId={homeId} />
        </Provider>
    );
}

const userBadge = document.querySelectorAll('.user-badge');
if (userBadge) {
    const UserBadgeHomeView = () => {
        const { level, isLoading } = useBadge(userId);

        if (isLoading) {
            return <Loading />;
        }

        return (
            <Provider store={store}>
                <UserBadge level={level} />
            </Provider>
        );
    };

    [].forEach.call(userBadge, (element) => {
        createRoot(element).render(<UserBadgeHomeView />);
    });
}

const userExchangeReviews = document.getElementById('user-exchange-reviews');
if (userExchangeReviews) {
    const root = createRoot(userExchangeReviews);
    root.render(
        <Provider store={store}>
            <ExchangesReviewsInfo userId={userId} />
        </Provider>
    );
}

// Initialize Verification Indicators
const userVerificationIndicators = document.getElementById('user-verification-indicators');
if (userVerificationIndicators) {
    createRoot(userVerificationIndicators).render(
        <Provider store={store}>
            <VerificationIndicators userId={userId} withDivider={false} />
        </Provider>
    );
}

const userVerificationIndicatorsResponsive = document.getElementById(
    'user-verification-indicators-responsive'
);
if (userVerificationIndicatorsResponsive) {
    createRoot(userVerificationIndicatorsResponsive).render(
        <Provider store={store}>
            <VerificationIndicators userId={userId} withDivider={false} />
        </Provider>
    );
}

if (isLogged) {
    Api.ResponseTime.fetchResponseTime(userId)
        .then((response) => {
            const responseTimeContainer = document.querySelectorAll('.user-response-time');
            const { response_time: responseTime, is_new_user: isNewUser } = response;
            if ((responseTime === null || responseTime === undefined) && isNewUser) {
                const responseRateContainer = document.querySelectorAll('.user-response-rate');
                [].forEach.call(responseRateContainer, (el) => {
                    el.style.display = 'none';
                });
                [].forEach.call(responseTimeContainer, (el) => {
                    createRoot(el).render(<NewUserLabel label={i18n.t('common:new-user')} />);
                });
            } else {
                [].forEach.call(responseTimeContainer, (el) => {
                    createRoot(el).render(<ResponseTime responseTime={response} isHomeView={true} />);
                });
            }
        })
        .catch((error) => {
            console.error(error.responseJSON);
        });
}

// Initialize Carousel
const homeViewCarouselElements = document.querySelectorAll('.home-view-carousel');
[].forEach.call(homeViewCarouselElements, (element) => {
    createRoot(element).render(
        <Provider store={store}>
            <HomeViewCarousel homeId={homeId} />
        </Provider>
    );
});
const calendarView = document.getElementById('calendar-view');
if (calendarView) {
    createRoot(calendarView).render(
        <Provider store={store}>
            <HomeViewCalendar homeId={homeId} />
        </Provider>
    );
}

const calendarUpdateView = document.getElementById('home-view-calendar-caption-update');
if (calendarUpdateView) {
    createRoot(calendarUpdateView).render(
        <Provider store={store}>
            <CalendarCaptionUpdate homeId={homeId} />
        </Provider>
    );
}

// Initialize Home Description
const homeViewDescription = document.getElementById('home_description');
if (homeViewDescription) {
    createRoot(homeViewDescription).render(
        <Provider store={store}>
            <HomeDescription homeId={homeId} />
        </Provider>
    );
}

// Initialize Rating
const homeRating = document.getElementById('home_rating_react');
if (homeRating) {
    createRoot(homeRating).render(
        <Provider store={store}>
            <HomeRating homeId={homeId} />
        </Provider>
    );
}

// Initialize Rating
const aggregateRatings = document.getElementsByClassName('aggregate-rating');
if (aggregateRatings) {
    Array.prototype.forEach.call(aggregateRatings, (aggregateRating) => {
        createRoot(aggregateRating).render(
            <Provider store={store}>
                <HomeAggregateRating homeId={homeId} />
            </Provider>
        );
    });
}

// Contact buttons
const contactButtons = document.querySelectorAll('.contact-buttons');
[].forEach.call(contactButtons, (element) => {
    createRoot(element).render(
        <Provider store={store}>
            <AnalyticsProvider eventsApiOptions={{ timeout: 1000 }}>
                <ContactButton homeId={homeId} patchHome={updateHome} />
            </AnalyticsProvider>
        </Provider>
    );
});

// User ambassador
const userAmbassadorContainers = document.getElementsByClassName('user-ambassador');
if (userAmbassadorContainers) {
    [].forEach.call(userAmbassadorContainers, (container) => {
        createRoot(container).render(
            <Provider store={store}>
                <UserAmbassador />
            </Provider>
        );
    });
}

// User sponsor
const userSponsorContainer = document.getElementById('user-sponsor');
if (userSponsorContainer) {
    createRoot(userSponsorContainer).render(
        <Provider store={store}>
            <UserSponsor />
        </Provider>
    );
}

// User sponsor badge
const userSponsorBadgeContainer = document.querySelectorAll('.user-sponsor-badge-container');
[].forEach.call(userSponsorBadgeContainer, (element) => {
    if (userSponsorBadgeContainer.length > 0) {
        createRoot(element).render(
            <Provider store={store}>
                <UserSponsorBadge />
            </Provider>
        );
    }
});

const travelersContainer = document.querySelectorAll('.home-view-travelers-info');
if (travelersContainer.length > 0) {
    Api.Traveler.fetchTravelersComposition(userId).then((response) => {
        [].forEach.call(travelersContainer, (element) => {
            createRoot(element).render(
                <Provider store={store}>
                    <TravelingGroupInfo userId={userId} composition={response} />
                </Provider>
            );
        });
    });
}
const preferredDestinations = document.querySelectorAll('.preferred-destinations');
if (preferredDestinations.length > 0) {
    [].forEach.call(preferredDestinations, (element) => {
        createRoot(element).render(
            <Provider store={store}>
                <PreferredDestinations userId={userId} />
            </Provider>
        );
    });
}

const mapContainer = document.getElementById('map-container');
if (mapContainer) {
    createRoot(mapContainer).render(
        <Provider store={store}>
            <HomeMap homeId={homeId} />
        </Provider>
    );
}

const similarHomes = document.getElementById('similar-homes-react');
if (similarHomes && isLogged) {
    createRoot(similarHomes).render(
        <Provider store={store}>
            <HomepageNewest isLogged={isLogged} isSimilarHome={true} homeId={homeId} />
        </Provider>
    );
}

// Add home to the list of last viewed homes
Storage.addItem('homes_history', homeId, 'local', true, true);
